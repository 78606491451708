import { EntitlementEnum } from "@/openapi";
import { clsx } from "clsx";
import CheckIcon from "../icons/CheckIcon";

import svgCheck from "../../public/svg/check.svg";
import Image from "next/image";
import SimpleLockIcon from "../icons/SimpleLockIcon";
import { _ } from "@/pages/_app";
import InfoIcon from "../icons/InfoIcon";
import { trackEvent } from "@/utils/analyticsFunctions";
import Modal from "../misc/Modal";
import { devPrint } from "@/utils/devUtils";
import { useState } from "react";

interface IFeatureItemComponent {
  item: FeatureItem;
  index: number;
  key: string;
}
export const FeatureItemComponent = ({
  item,
  index,
  ...props
}: IFeatureItemComponent) => {
  var modalId = "feature" + item.title;

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Modal
        title={_(item.title)}
        body={_(item.subtitle)}
        id={modalId}
        show={showModal}
        onClose={() => setShowModal(false)}
      />
      <div
        onClick={() => {
          trackEvent("feature_item_info");
          setShowModal(!showModal);
        }}
        key={props.key}
        className={clsx(
          "flex text-sm p-3 justify-between rounded-md cursor-pointer",
          index % 2 == 0 ? "bg-lightest-gray" : "bg-white"
        )}
      >
        <div className="col-span-3 flex flex-row justify-start ">
          <CheckIcon className="mr-2" stroke="green" />
          {_(item.title)}
        </div>
        {/* {item.entitlement === "NONE" ? (
        <CheckIcon className="m-auto" stroke="green" />
        ) : (
          <SimpleLockIcon className="m-auto" stroke="black" />
          )}
          {item.entitlement === "BROWSING" || item.entitlement === "NONE" ? (
            <CheckIcon className="m-auto" stroke="green" />
            ) : (
              <SimpleLockIcon className="m-auto" stroke="black" />
            )} */}
        <button
          className=" mr-2 ml-2 cursor-pointer"
          data-modal-target={modalId}
          data-modal-toggle={modalId}
          onClick={() => { }}
        >
          <InfoIcon />
        </button>
      </div>
    </>
  );
};

const renderFeatureHeader = () => {
  return (
    <div className="grid grid-cols-6 mb-2 font-semibold text-stone-gray">
      <div className="col-span-3 ml-3"> {_("feature-list__title")}</div>
      <div className="m-auto">{_("sub__free")}</div>
      <div className="m-auto">{_("sub__browser")}</div>
      <div className="m-auto">{_("sub__premum")}</div>
    </div>
  );
};

const FeaturesList = () => {
  return (
    <div>
      <div>
        {renderFeatureHeader()}
        {features.map((e, i) => (
          <FeatureItemComponent item={e} index={i} key={e.title} />
        ))}
      </div>
    </div>
  );
};

export default FeaturesList;

export class FeatureItem {
  constructor(title: string, subtitle: string, entitlements: EntitlementEnum[]) {
    this.title = title;
    this.subtitle = subtitle;
    this.entitlements = entitlements;
  }
  title: string;
  subtitle: string;
  entitlements: EntitlementEnum[];
}

function getAllEntitlements(): EntitlementEnum[] {
  return ["NONE", "BROWSING", "PRO"];
}

function getLimitedEntitlements(): EntitlementEnum[] {
  return ["BROWSING", "PRO"];
}

function getPremiumEntitlement(): EntitlementEnum[] {
  return ["PRO"];
}

const features = [
  new FeatureItem(
    "sub__track-training-title",
    "sub__track-training-info",
    getAllEntitlements()
  ),
  new FeatureItem(
    "sub__register-activity-title",
    "sub__register-activity-info",
    getAllEntitlements()
  ),
  new FeatureItem("sub__create-teams-title", "sub__create-teams-info", getAllEntitlements()),
  new FeatureItem("sub__sf-results-title", "sub__sf-results-info", getAllEntitlements()),
  new FeatureItem(
    "sub__access-worldfengur-title",
    "sub__access-worldfengur-info",
    getLimitedEntitlements()
  ),
  new FeatureItem(
    "sub__follow-events-title",
    "sub__follow-events-info",
    getLimitedEntitlements()
  ),
  new FeatureItem(
    "sub__score-history-title",
    "sub__score-history-info",
    getLimitedEntitlements()
  ),
  new FeatureItem(
    "sub__judge-insights-title",
    "sub__judge-insights-info",
    getLimitedEntitlements()
  ),
  new FeatureItem(
    "sub__browse-whiteboard-title",
    "sub__browse-whiteboard-info",
    getLimitedEntitlements()
  ),
  new FeatureItem("sub__gait-analysis-title", "sub__gait-analysis-info", getPremiumEntitlement()),
  new FeatureItem(
    "sub__training-analysis-title",
    "sub__training-analysis-info",
    getPremiumEntitlement()
  ),
  new FeatureItem(
    "sub__safety-tracking-title",
    "sub__safety-tracking-info",
    getPremiumEntitlement()
  ),
  new FeatureItem(
    "sub__multi-select-horses-title",
    "sub__multi-select-horses-info",
    getPremiumEntitlement()
  ),
  new FeatureItem("sub__feeding-plan-title", "sub__feeding-plan-info", getPremiumEntitlement()),
  new FeatureItem("sub__trim-tracking-title", "sub__trim-tracking-info", getPremiumEntitlement()),
  // new FeatureItem(
  //   "sub__stable-location-title",
  //   "sub__stable-location-info",
  //   "PRO"
  // ),
  new FeatureItem(
    "sub__planning-assgning-title",
    "sub__planning-assgning-info",
    getPremiumEntitlement()
  ),
  // new FeatureItem("sub__export-data-title", "sub__export-data-info", getPremiumEntitlement()),
  // new FeatureItem("sub__ad-free-title", "sub__ad-free-info", "PRO"),
];

export const getAllFeatures = () => {
  return features;
}

export const getFeatures = (entitlement: EntitlementEnum) => {
  var res = [];
  for (let item of features) {
    if (item.entitlements.includes(entitlement)) {
      res.push(item);
    }
  }
  return res;
};
